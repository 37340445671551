import * as React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { SeoComponent } from "../components/seo";
import breakpoints from "../components/breakpoints";

const InnoInvest = styled.section`
  position: relative;
  display: block;
  overflow: hidden;
  color: #333;
  padding: 80px 25% 20px 25%;
  @media (max-width: ${breakpoints.tablet}) {
    padding: 80px 10% 20px 10%;
  }
`;
const Paragraph = styled.p`
  color: #ccc;
  font-size: 10pt;
`;

const Source = styled.p`
  position: relative;
  display: flex;
  justify-content: end;
  font-size: 8pt;
  opacity: 0.2;
  color: #999;
  a {
    color: #999;
    text-decoration: none;
    font-size: 8pt;
  }
`;

const InnoInvestPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SeoComponent title="No Index Page" noIndex={true} />
      <InnoInvest>
        <h1>InnoInvest</h1>
      </InnoInvest>
    </Layout>
  );
};
export default InnoInvestPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
